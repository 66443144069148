import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { Link, LinkProps } from '@managers/links/link';
import { Announcement } from '@kit/announcement';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NotFoundAnnouncementWidget: AppFC = () => {
  const buttonProps: LinkProps = useMemo(() => {
    return {
      routeDescriptor: ROUTES.index.getDescriptor(),
      children: 'Перейти на главную'
    };
  }, []);
  return _jsx(Announcement //
  , {
    title: "\u041E\u0448\u0438\u0431\u043A\u0430 404",
    message: "\u0423\u043F\u0441... \u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430 :(",
    image: "/images/bg/404-page-bg.png",
    buttonComponent: Link,
    buttonProps: buttonProps
  });
};